<template>
    <v-container fluid>
        <v-switch
            v-model="ativoInterno"
            :label="dadosForm('rotulo')"
            @change="onChange"
            color="success"
            hide-details
        ></v-switch>
    </v-container>
</template>

<script>
import { FormCampoRetornaDado } from '@/helpers/Componente.js';

export default {
    props: ['value', 'form', 'campo'],
    data() {
        return {
            ativoInterno: false,
            opcoes: 'S',
        };
    },
    watch: {
        value() {
            this.ativoInterno = this.value == 'S' ? true : false;
        },
    },
    created() {},
    computed: {},
    methods: {
        dadosForm(campoSelecionado) {
            return FormCampoRetornaDado(
                this.form,
                this.campo,
                campoSelecionado
            );
        },
        onChange() {
            if (this.ativoInterno) {
                this.opcoes = 'S';
            }

            if (!this.ativoInterno) {
                this.opcoes = 'N';
            }

            this.$emit('input', this.opcoes);
        },
    },
};
</script >

<style>
.theme--light.v-input--switch .v-input--switch__thumb {
    color: red;
}

.theme--light.v-input--switch .v-input--switch__track {
    color: red;
}
</style>
